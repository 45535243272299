import React from 'react'
import styled, {css} from 'styled-components'
import {vw, genID} from '../utilities/helpers'
import Blob from './blob'

const Grid = styled.section`  
    position: relative; 

    :after{
        content:"";
        display: table;
        clear: both;
    }

    > a {
        float: left;
        overflow: hidden;
    }

    ${props => props.col && css`
        display: flex; 
        flex-wrap: wrap;
        justify-content: flex-start;

        > a{
            width: ${100/props.col}%;
            float: none;

            > div {
                margin: 0 ${vw(0.4)};
            }
        }    
    `}

    @media (max-width: 767px){
        > a {
            flex: 1 1 50%;

            > div {
                margin: 0 9px 24px;
            }
        }
    }

`

function createCell(props){
    return(
        <Blob id={genID()} {...props} />
    )
}

export default (props) => {
    return(
        <Grid col={props.col}>
            {props.cells.map(createCell)}
        </Grid>
    )
}