import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import inView from 'in-view'

import Button from './button'
import Line from './line'
import Text from './text'

import { vw, fadeInUp, easings} from '../utilities/helpers'


const Excerpt  = styled.section`
  position: absolute;
  z-index: 1;
  top: ${vw(3.5)};
  width: ${vw(7)};

  &.is-visible{
    h1, p, a{
      animation: ${fadeInUp} 1s ${easings.easeOutQuad} both;
    }

    a{
      animation-delay: 0.41s;
    }

    p{
      animation-delay: 0.2s;
    }
  }

  h1{
    
    
    span:first-child {
      text-transform: uppercase;
      padding-bottom: ${vw(0.1)};
    }
    
  }

  > div{
    padding-top: ${vw(1)};
  }

  a{
    margin-top: ${vw(0.5)};
    margin-left: ${vw(1)};
    
  }

  p{
    margin:0 ${vw(1)};
    padding-bottom: ${vw(0.25)};
    position: relative;
  }

  ${props => props.direction === 'left' && css`
    left: ${vw(7)};
  `}

  ${props => props.direction === 'xleft' && css`
    left: ${vw(2)};
    width: ${vw(8)};
    top: ${vw(3)};
  `}

  ${props => props.direction === 'right' && css`
    left: ${vw(9)};
  `}


  @media (max-width: 1023px){
    
      ${props => props.direction === 'xleft' && css`
        width: ${vw(10)};
        left: ${vw(1)};
      `}

      ${props => props.direction === 'right' && css`
        width: 50%;
        margin-left: ${vw(1)};
        position: static;
      `}

      ${props => props.direction === 'left' && css`
        width: 50%;
        margin-left: ${vw(10)};
        position: static;
      `}
  }

  @media (max-width: 767px){
    position: static;
    width: 90%;
    margin: 0 auto;

    p{
      margin: 0;
      padding: 24px 0;
      animation: none;
    }

    a{
      margin-left: 0;
    }
  }
`

export default (props) => {
  const obj = useRef(null)
  const [isVisible, setVisible] = useState('')
  
  useEffect(() => {
    inView.threshold(0)
    
    const onScroll = () => {
      if (inView.is(obj.current)) {
        setVisible('is-visible')
      }
    }
    onScroll()
    document.addEventListener("scroll", onScroll)

    return () => {
        document.removeEventListener("scroll", onScroll)
    }
  },[])

  return (
    <Excerpt direction={props.direction} ref={obj} className={isVisible}>
      <h1>
        <Text tiny>{props.subtitle}</Text>
        <Text xlarge>{props.title}</Text>
      </h1>
      <div>
        <p>{props.caption} <Line /></p>
        <Button link to={'/'+props.to} label={props.action}/>
      </div>
    </Excerpt>
  )
}