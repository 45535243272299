import React from 'react'
import styled from 'styled-components'

import Text from './text'
import { percent, vw } from '../utilities/helpers'
import zig from '../assets/images/zig-red.svg'


const Box = styled.div`
    overflow: scroll;
    position: absolute;
    width: 75%;
    left: 7.5%;

    :after{
        content: "";
        display: table;
        clear: both;
    }

    @media (max-width: 767px){
        height: 80vh;
        width: 100%;
        left: auto;
    }
`

const Image = styled.figure`
    float: left;
    width: ${percent(1/3)};
    position: relative;

    svg{
        width: 100%;
        height: 100%;
        position: relative;
    }

    @media (max-width: 767px){
        float: none;
        width: auto;
        margin: ${vw(1)} auto;

        svg {
            margin: 0 auto;
        }
    }
`

const Copy = styled.section`
    float: right;
    width: ${percent(2/3)};

    @media (max-width: 767px){
        float: none;
        width: auto;
    }
`

const Wrap = styled.section`
    margin: ${vw(1)};
`

const Block = styled.section`
    padding: ${vw(1)} 0 ${vw(1)};
    position: relative; 

    :after{
        content: "";
        height: ${vw(0.125)};
        width:${vw(2)};
        background-image: url('${zig}');
        background-repeat: repeat-x;
        background-size: contain;
        bottom: 0;
        left: 0;
        position: absolute;
    }

    @media (max-width: 767px){
        padding: ${vw(2)} 0 ${vw(2)};
        :after{
            height: ${vw(1)}
        }
    }
`

export default (props) => {
    return(
        <Box>
            <Image>
                <svg viewBox="0 0 153.5 131.9" preserveAspectRatio="xMidYMid slice">
                    <g mask={'url(#fg' + props.id + ')'}>
                        <image xlinkHref={props.fg} x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMidYMid slice"></image>
                    </g>
                </svg>
            </Image>
            <Copy>
                <Wrap>
                    <h2>
                        <Text large>{props.title}</Text>
                        <Text normal>{props.subtitle}, {props.organization}</Text>
                    </h2>
                    
                    <Block dangerouslySetInnerHTML={{ __html: props.bio }} />
                    
                    {props.sessions &&
                        <Block>
                            <h3><Text med>IGD Sessions</Text> </h3>
                            <div className='sessions-list' dangerouslySetInnerHTML={{ __html: props.sessions }} />
                        </Block>
                    }
                </Wrap>
            </Copy>
        </Box>
    )
}

