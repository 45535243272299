import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Collage from './collage'
import Excerpt from './excerpt'

import { vw } from '../utilities/helpers'

const duration = 10

const Box = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  @media (max-width: 1023px){
    padding-top: ${vw(1)};
  }

  @media (max-width: 767px){
    position: static;
    height: auto;
  }
`

const Slide = styled.section`
  position: relative;
  ${'' /* display: none;
  animation: ${fadeOut} 1s ${duration - 1}s; */}
`

export default () => {  
  //const requestRef = React.useRef()

  const data = useStaticQuery(graphql`
    query SlidesQuery {
      allPrismicHome {
        edges {
          node {
            data {
              slides {
                slide_back {
                  url
                }
                slide_caption {
                  text
                }
                slide_front {
                  url
                }
                slide_link_label {
                  text
                }
                slide_link {
                  uid
                }
                slide_subtitle {
                  text
                }
                slide_title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  const slides = data.allPrismicHome.edges[0].node.data.slides
  const slide = slides[Math.floor(Math.random() * slides.length)]
  
  // useEffect(() => {
  //   var slideIndex = 0
  //   var frames = duration * 60
    
  //   // let counter = document.getElementById('slideCounter')

  //   const playSlides = () => {
      
  //     if (frames === (duration * 60)) {
  //       var i
  //       var slides = document.getElementsByClassName("slide")

  //       for (i = 0; i < slides.length; i++) {
  //         slides[i].style.display = "none"
  //       }
  //       slideIndex++

  //       if (slideIndex > slides.length) {
  //         slideIndex = 1
  //       }
  //       slides[slideIndex - 1].style.display = "block"

  //       frames = 0
  //     }

  //     frames++
  //     requestRef.current = window.requestAnimationFrame(playSlides)
  //     // counter.innerHTML = frames
  //   }

  //   requestRef.current = requestAnimationFrame(playSlides)
  //   return () => cancelAnimationFrame(requestRef.current)
  // }, [])

  // function createSlide(slide, index) {
  //   return (
  //     <Slide key={index} className="slide">
  //       <Collage front={slide.slide_front.url} back={slide.slide_back.url} duration={duration} />
  //       <Excerpt direction="xleft" subtitle={slide.slide_subtitle.text} title={slide.slide_title.text} caption={slide.slide_caption.text} action={slide.slide_link_label.text} to={slide.slide_link.uid} />
  //     </Slide>
  //   )
  // }

  return (
    <Box>
      <Slide>
        <Collage front={slide.slide_front.url} back={slide.slide_back.url} duration={duration} />
        <Excerpt direction="xleft" subtitle={slide.slide_subtitle.text} title={slide.slide_title.text} caption={slide.slide_caption.text} action={slide.slide_link_label.text} to={slide.slide_link.uid} />
      </Slide>
    </Box>
  )
}