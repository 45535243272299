import React, { useRef, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import inView from 'in-view'

import { vw, easings,scaleIn, scaleInBig, scaleOut, genID, count } from '../utilities/helpers'

import Text from './text'
import Bio from './bio'

const Box = styled.a`
    display: block;
    width: 100%;
    text-align: center;
    
    opacity: 0;
    transform: translate3d(0,${vw(3)},0) scale3d(1.3,1.3,1.3);
    transition: opacity 1s, transform 1.5s;
    transition-timing-function: ${easings.easeOutQuint};

    &.is-visible{
        opacity: 1;
        transform: none;
    }

    ${props => props.video && css`
        .fg image {
            animation-name: ${scaleInBig};
        }
    `}

    ${props => props.type !== 'text' && css`
        cursor: pointer;
    `}

    image, .bg{
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        animation-play-state: paused;
        transform-origin: 50% 50%;
    }

    image{
        animation-name: ${scaleIn};
        animation-duration: 8s;
    }

    .bg{
        animation-name: ${scaleOut};
        animation-duration: 5s;
    }

    :hover{
        image, .bg {
            animation-play-state: running;
        }
    }

    @media (max-width: 767px){
        width: 100%;
    }
`

const Wrap = styled.div`
    position: relative;
    padding-bottom: ${vw(0.75)}
`

const Fill = styled.div`
    position: relative;
    margin-bottom: ${vw(0.25)};

    svg{
        width: 100%;
        height: 100%;
    }

    mask{
        fill: #fff;
    }
`

const Overlay = styled.div`
    position: absolute; 
    width: 100%;
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 2;

    div {
        position: relative;
        > span{
            position: relative
        }
    }

    i{
        position: absolute;
        right: -25%;
        top: 25%;
    }
    
    i, span{
        color: #000;
        font-weight: 500;
    }
`

const Icon = styled.img`
    object-fit: contain;
    width:20%;
    height: 20%;
    top: ${vw(0.2)};
    position: ${props => props.type === "text" ? 'absolute' : 'static'};
`

const Title = styled.div`
    padding: ${vw(0.05)} 0 ${vw(0.05)};
    
    span {
        text-align: center;
        font-weight: bold;
    }

    @media (max-width: 1023px){
        padding-top: ${vw(0.5)};
    }
`

const SubTitle = styled.div` 
    span {  
        font-family: 'Playfair Display';
        font-style: italic;
        font-weight: 700;
        text-align: center;
    } 
`

const paths = {
    1: 'M98.6,5.9C64.7-6.6,16.9,0.7,3.8,27.8c-16.3,33.8,21.4,97.7,70.5,99.4c37.2,1.2,77.5-33.4,74.5-68.6 C145.8,24,102.8,7.5,98.6,5.9z',
    2: 'M101.6,5.9C74.1-4.2,27.8-4.2,8.1,29.6c-18.7,32.1-2.5,75.7,23.8,91.2c43.8,25.9,119.9-24,118.6-64.7 C149.6,31.9,121.2,13,101.6,5.9z',
    3: 'M97.8,5.2C63.3-8.3,16.1,4.8,3.2,40c-10.9,29.7,7.2,63.3,31.2,78.5c37.3,23.6,97.5,7.8,110-30.3 C154.6,57.3,131.2,18.3,97.8,5.2z',
    4: 'M100,3.1C57.5-10.3,1.6,22,0,64C-1,93,24,120.9,52.2,128c44.8,11.4,97.1-29.6,94.6-70.8 C145.1,31.8,122.9,10.3,100,3.1z',
    5: 'M117.6,9.4c-42.4-26.6-112.9,8-117.4,48C-3.3,89,34,125.8,74.5,129.1c22.6,1.8,56.5-6,70.4-34.2 C160.1, 63.9, 142.8, 25.2, 117.6, 9.4z',
    6: 'M20.1,21C46-6.3,84.1,0.6,87.8,1.3c40.3,7.9,71.2,49.1,64.9,77.6c-9.6,43.1-107.2,69.7-139.6,32.4 C-5.2,90.3-4.8,47.1,20.1,21z'
}

function createAlbum(item, index){
    return (
        <div key={index} className='album-slide'>
            <img src={item} alt="IGD" />
        </div>
    )
}

let photoList = []

export default (props) => {
    const obj = useRef(null)
    const album = useRef(null)
    const bio = useRef(null)
    const counter = useRef(null)
    
    const [isVisible, toggleView] = useState('is-hidden')

    var id = props.id
    if(!id) {
        id = genID()
    }

    const fgID = 'fg' + id
    const bgID = 'bg' + id
    const pathID = 'path' + id
    const end = props.number
    const unit = props.unit


    useEffect(() => {
        let duration = 1000
        let start = end - 20

        if(start < 0) {
            start = 0
        }
        

        inView.threshold(0)

        let slides = false
        let prevBtn = false
        let nextBtn = false
        let activeSlide = 1
        let html = ''

        const body = document.documentElement
        const lightbox = document.querySelector('.lightbox')
        const lightboxContent = document.querySelector('.lightbox-media')
        const audio = document.getElementById('powerquotes')

        function showSlide(n) {
            var i
    
            if (n > slides.length) {
                activeSlide = slides.length
            }
            
            if (n < 1) {
                activeSlide = 1
            }
            
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none"
            }
            
            slides[activeSlide-1].style.display = "block"
        }

        if (props.photos) {
            props.photos.forEach(function (item, index) {
                photoList[index] = [item.url]
            })
        }

        //click events
        obj.current.addEventListener("click", function (e) {

            if(props.video || props.bio || props.photos){
                body.classList.add('u-oh')
                e.preventDefault()
            }

            if(props.video || props.photos){
                const preloader = document.querySelector('.video-preloader')
                preloader.classList.remove('is-hidden')
            }

            if (props.video){
                const url = props.video
                const vidID = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/)

                if (vidID != null) {
                    lightbox.classList.add('is-visible')
                    lightboxContent.innerHTML = '<iframe src="https://www.youtube.com/embed/' + vidID[1] + '?autoplay=1" title="video" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen/>'

                    if(!audio.paused) {
                        audio.pause()
                    }
                }
            }

            if(props.bio){
                html = ''
                html += bio.current.innerHTML
                lightboxContent.innerHTML = html

                lightbox.classList.add('is-visible')
            }

            if (props.photos) {
                html = '<div class="album"><a href="#" id="prevBtn" class="album-button prev">Prev</a><a id="nextBtn" href="#" class="album-button next">Next</a><div class="album-photos">'
                html += album.current.innerHTML
               
                lightboxContent.innerHTML = '' + html + '</div></div>'

                prevBtn = document.getElementById('prevBtn')
                nextBtn = document.getElementById('nextBtn')
                slides = document.getElementsByClassName("album-photos")[0].childNodes

                lightbox.classList.add('is-visible')

                showSlide(0)
        
                if(prevBtn){
                    prevBtn.addEventListener('click', function (e) {
                      e.preventDefault()
                      showSlide(activeSlide -= 1)
                    })
                }
                
                if(nextBtn){
                    nextBtn.addEventListener('click', function (e) {
                        e.preventDefault()        
                        showSlide(activeSlide += 1)
                    })
                }
            }
        })
       
        //animate on scroll
        const onScroll = () => {
            if (inView.is(obj.current)) {
                toggleView('is-visible')

                if (props.type === 'text') {
                    count(counter.current, start, end, duration, unit)
                    
                    start = end
                    duration = 0
                }
            }

            
        }
        onScroll()
        document.addEventListener("scroll", onScroll)

        return () => {
            document.removeEventListener("scroll", onScroll)
        }
    })

    return (
        <Box size={props.size} ref={obj} className={isVisible} href={props.href} target="_blank" video={props.video}>
            <Wrap>
                <Fill size={props.size}>

                    {props.type !== 'text' &&
                        <svg viewBox="0 0 153.5 131.9">
                            <defs>
                                <mask id={fgID}>
                                    <path id={pathID} d={paths[1]} />
                                </mask>
                                <mask id={bgID}>
                                    <path d={paths[6]} className="bg"/>
                                </mask>
                            </defs>
                            <g mask={'url(#' + bgID + ')'}>
                                <image xlinkHref={props.bg} x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMidYMid slice"></image>
                            </g>

                            <g mask={'url(#' + fgID + ')'} className="fg">
                                <image xlinkHref={props.fg} x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMidYMid slice"></image>
                            </g>
                        </svg>
                    }

                    {props.type === 'text' &&
                        <svg viewBox="0 0 153.5 131.9" >
                            <defs>
                                <mask id={bgID}>
                                    <path d={paths[1]} className="bg"/>
                                </mask>
                            </defs>
                            <g mask={'url(#' + bgID + ')'}>
                                <image xlinkHref={props.bg} x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMidYMid slice"></image>
                            </g>
                            <path id={pathID} fill={props.fill} d={paths[2]} />
                        </svg>
                    }

                    <Overlay>
                        {props.type === 'text' &&
                            <div>
                            <Text xlarge id={id}>
                                <span ref={counter}></span></Text>
                            </div>
                        }

                        {props.icon &&
                            <Icon type={props.type} src={props.icon} />
                        }
                    </Overlay>

                </Fill>

                {props.type !== 'text' &&
                    <Title><Text normal>{props.title}</Text></Title>
                }

                <SubTitle type={props.type}><Text small>{props.subtitle}</Text></SubTitle>
                
                {props.photos &&
                    <div className="hide" ref={album}>
                        {photoList.map(createAlbum)}
                    </div>
                }

                {props.bio && 
                    <div className="hide" ref={bio}>
                        <Bio id={fgID} {...props} />
                    </div>
                }
            </Wrap>
        </Box>
    )
}