import React from "react";
import { vw, colors, patterns, icons } from "../utilities/helpers";
import styled, { css } from "styled-components";
import { graphql } from "gatsby";

import SEO from "../utilities/seo";
import { paths } from "../utilities/helpers";

import Block from "../components/block";
import Slider from "../components/slider";
import Excerpt from "../components/excerpt";
import Hero from "../components/hero";
import Bit from "../components/bit";
import Glow from "../components/glow";
import Table from "../components/grid";
import Next from "../components/next";
import Footer from "../components/footer";
import Lightbox from "../components/lightbox";

const Grid = styled.section`
	position: absolute;
	top: ${vw(3.5)};
	left: ${vw(1)};
	width: ${vw(7)};

	${(props) =>
		props.direction === "left" &&
		css`
			left: auto;
			right: ${vw(1)};
		`}

	@media (max-width: 1023px) {
		position: static;
		width: 85%;
		margin: 0 auto;
		padding-top: ${vw(2.5)};

		a {
			flex: 1 1 25%;
		}
	}

	@media (max-width: 767px) {
		width: 90%;
		margin: 0 auto;
		padding-top: ${vw(4)};

		a {
			flex: 1 1 50%;
		}
	}
`;

const Bkg = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;

	> svg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		:first-child {
			z-index: 1;
		}

		:last-child {
			filter: grayscale(100);
			z-index: -1;
		}
	}
`;

const vidList = [];
const docList = [];
const statList = [];
const speakerList = [];

function createSlices(slice, index) {
	var cells = [];
	var node = slice.primary;
	var even = index % 2 === 0;
	var direction = "";
	var type = node.featured_content;

	switch (type) {
		case "Speakers":
			cells = speakerList;
			break;

		case "Videos":
			cells = vidList;
			break;

		case "Reports":
			cells = docList;
			break;

		case "Statistics":
			cells = statList;
			break;

		default:
			break;
	}

	even ? (direction = "left") : (direction = "right");

	return (
		<Block index key={index}>
			{!even && (
				<Hero home right={vw(1)} top={vw(3)}>
					<img
						src={node.home_section_fore.url}
						alt={node.home_section_title.text}
					/>
				</Hero>
			)}

			{even && (
				<Hero home left={vw(1)} top={vw(3)}>
					<img
						src={node.home_section_fore.url}
						alt={node.home_section_title.text}
					/>
				</Hero>
			)}

			<Excerpt
				direction={direction}
				title={node.home_section_title.text}
				caption={node.home_section_caption.text}
				action={node.label.text}
				to={node.link_slug.uid}
			/>

			<Grid direction={direction}>
				<Table tiny col={2} cells={cells} />
			</Grid>

			{!even && (
				<Bkg>
					<Bkg>
						<svg
							viewBox="0 0 1320 720"
							preserveAspectRatio="xMidYMid slice"
						>
							<defs>
								<linearGradient
									id="fillA"
									gradientUnits="userSpaceOnUse"
									gradientTransform="rotate(90)"
								>
									<stop offset="0" stopColor="#4744E5" />
									<stop
										offset="0.45"
										stopColor="#000"
										stopOpacity="0"
									/>
								</linearGradient>
							</defs>
							<g transform="translate(660,0)">
								<path fill="url(#fillA)" d={paths.h} />
							</g>
						</svg>

						<svg
							viewBox="0 0 1320 720"
							preserveAspectRatio="xMidYMid slice"
						>
							<defs>
								<linearGradient
									id="featherA"
									gradientUnits="objectBoundingBox"
									gradientTransform="rotate(90)"
								>
									<stop offset="0" stopColor="#fff" />
									<stop
										offset="1"
										stopColor="#000"
										stopOpacity="0"
									/>
								</linearGradient>
								<mask id="maskA">
									<path fill="url(#featherA)" d={paths.a} />
								</mask>
							</defs>
							<g mask="url(#maskA)" opacity="0.5">
								<image
									xlinkHref={node.home_section_bkg.url}
									x="0"
									y="0"
									width="100%"
									height="100%"
									preserveAspectRatio="xMidYMid slice"
								></image>
							</g>
						</svg>
					</Bkg>

					<Bit cross top={vw(2)} left={vw(11)} />
					<Bit ring top={vw(5)} right={vw(6)} />
					<Bit arrow top={vw(10)} right={vw(8)} />

					<Glow royal bottom={vw(-4)} right={vw(-3)} delay="0" />
					<Glow cool bottom={vw(-6)} left={vw(-3)} delay="0.75" />
				</Bkg>
			)}

			{even && (
				<Bkg>
					<Bit ring top={vw(3.5)} left={vw(8)} />
					<Bit cross top={vw(10)} left={vw(4)} />

					<Glow cool bottom={vw(-6)} left={vw(-4)} delay="0" />
					<Glow royal bottom={vw(-4)} right={vw(-3)} delay="0.75" />

					<Bkg>
						<svg
							viewBox="0 0 1320 720"
							preserveAspectRatio="xMidYMid slice"
						>
							<defs>
								<linearGradient
									id="fillB"
									gradientUnits="objectBoundingBox"
									gradientTransform="rotate(90)"
								>
									<stop offset="0" stopColor="#4744E5" />
									<stop
										offset="1"
										stopColor="#000"
										stopOpacity="0"
									/>
								</linearGradient>
							</defs>
							<path fill="url(#fillB)" d={paths.e} />
						</svg>

						<svg
							viewBox="0 0 1320 720"
							preserveAspectRatio="xMidYMid slice"
						>
							<defs>
								<linearGradient
									id="featherB"
									gradientUnits="userSpaceOnUse"
									gradientTransform="rotate(90)"
								>
									<stop offset="0" stopColor="#fff" />
									<stop
										offset="0.5"
										stopColor="#fff"
										stopOpacity="0"
									/>
								</linearGradient>
								<mask id="maskB">
									<path fill="url(#featherB)" d={paths.d} />
								</mask>
							</defs>
							<g mask="url(#maskB)" opacity="0.3">
								<image
									xlinkHref={node.home_section_bkg.url}
									x="0"
									y="0"
									width="100%"
									height="100%"
									preserveAspectRatio="xMidYMid slice"
								></image>
							</g>
						</svg>
					</Bkg>
				</Bkg>
			)}
		</Block>
	);
}

export default ({ data }) => {
	const slices = data.allPrismicHome.nodes[0].data.body;
	const reports = data.allPrismicDocument.edges;
	const videos = data.allPrismicVideo.edges;
	const speakers = data.allPrismicSpeaker.edges;
	const stats = data.allPrismicHome.nodes[0].data.stats;

	for (var i = 0; i < reports.length; i++) {
		const node = reports[i].node.data;
		docList[i] = {
			fg: node.cover.url,
			title: node.title,
			bg: patterns.green,
			key: i,
		};
	}

	for (var k = 0; k < videos.length; k++) {
		const node = videos[k].node.data;
		vidList[k] = {
			fg: node.link.thumbnail_url,
			video: node.link.embed_url,
			title: node.title.text,
			subtitle: node.subtitle.text,
			bg: patterns.green,
			key: k,
			icon: icons.play,
		};
	}

	for (var j = 0; j < speakers.length; j++) {
		const node = speakers[j].node.data;
		speakerList[j] = {
			fg: node.photo.url,
			title: node.name.text,
			organization: node.organization.text,
			bio: node.bio.html,
			subtitle: node.title.text,
			bg: patterns.green,
			key: j,
		};
	}

	for (var p = 0; p < stats.length; p++) {
		const item = stats[p];

		let unit = "";
		let number = "";

		if (item.unit !== null) {
			unit = item.unit.text;
		}

		if (item.number !== null) {
			number = item.number;
		}

		statList[p] = {
			type: "text",
			subtitle: item.caption.text,
			icon: item.icon.url,
			bg: patterns.green,
			fill: colors.green,
			key: p,
			unit: unit,
			number: number,
		};
	}

	return (
		<>
			<main>
				<SEO
					description={data.allPrismicHome.nodes[0].data.preface.text}
					title={data.allPrismicHome.nodes[0].data.title.text}
					image={data.allPrismicHome.nodes[0].data.image.url}
				/>

				{/* <Block>
        <Lightbox inline src="https://www.youtube.com/embed/7hr08ESDGRU" />
      </Block> */}

				<Block>
					<Slider />

					<Bit ring top={vw(3)} left={vw(10)} />
					<Bit cross top={vw(6)} left={vw(12)} />
					<Bit arrow top={vw(5)} right={vw(2)} />
					<Bit arrow top={vw(9.5)} right={vw(2)} />
					<Bit zig top={vw(7)} right={vw(1)} />

					<Glow cool top={vw(-4)} left={vw(-4)} delay="0" />
					<Glow warm bottom={vw(-6)} left={vw(-2)} delay="0.75" />
					<Glow royal bottom={vw(-2)} right={vw(-4)} delay="1.5" />
				</Block>

				{slices.map(createSlices)}

				<Next to="/about" title="About" />
			</main>
			<Footer />
		</>
	);
};

export const query = graphql`
	query SliceQuery {
		allPrismicHome {
			nodes {
				data {
					body {
						... on PrismicHomeDataBodySection {
							primary {
								home_section_bkg {
									url
								}
								home_section_caption {
									text
								}
								home_section_fore {
									url
								}
								home_section_title {
									text
								}
								link_slug {
									uid
								}
								label {
									text
								}
								featured_content
							}
						}
					}
					title {
						text
					}
					image {
						url
					}
					preface {
						text
					}
					stats {
						caption {
							text
						}
						icon {
							url
						}
						unit {
							text
						}
						number
					}
				}
			}
		}
		allPrismicDocument(limit: 4) {
			edges {
				node {
					data {
						cover {
							url
						}
						link {
							url
						}
						title {
							text
						}
					}
				}
			}
		}
		allPrismicVideo(limit: 4) {
			edges {
				node {
					data {
						title {
							text
						}
						subtitle {
							text
						}
						link {
							thumbnail_url
							embed_url
						}
					}
				}
			}
		}
		allPrismicSpeaker(limit: 4) {
			edges {
				node {
					data {
						bio {
							html
						}
						name {
							text
						}
						organization {
							text
						}
						title {
							text
						}
						photo {
							url
						}
					}
				}
			}
		}
	}
`;
