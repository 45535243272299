import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import {vw, icons, colors, spin, easings } from '../utilities/helpers'

import Line from './line'
import Text from './text'

const Next = styled.div`
    height: ${vw(3)};
    background-size: ${vw(0.25)};
    width: 50%; 
    margin: ${vw(1)} auto 0;
    position: relative;

    a{
        display: block;
        height: 100%;
        color: ${colors.green};
        margin-left: ${vw(1)};
        background-image: url('${icons.arrow}');
        background-position: right center;
        background-size: ${vw(0.25)};
        background-repeat: no-repeat;

        transition: opacity 0.75s;

        :hover{
            opacity: 0.75;

            div{
                transform: scale3d(0.75,0.75,0.75)
            }

            svg{
                animation-play-state: running;
            }
        }
    }

    svg{
        animation: ${spin} 10s linear infinite both;
        animation-play-state: paused;
    }

    path{
        fill:#000;
        opacity: 0.5;
    }

    @media (max-width: 767px){
        width: 80%;
        height: auto;
        padding: ${vw(0.5)} 0 ${vw(3)};

        a{
            background-size: ${vw(0.75)};
            margin-left: 0;
            margin-top: ${vw(0.5)};
        }
    }
`

const Blob = styled.div`
    position: absolute;
    top:-10%;
    left: 0;
    z-index: -1;
    width: 50%;
    height: 100%;
    transition: transform 0.75s ${easings.easeOutQuint};
    transform:scale3d(0,0,0);
`

const Subtitle = styled.span`
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    padding: ${vw(0.5)} ${vw(0.05)} ${vw(0.25)};
    display: block;
`

export default(props) => (
    <Next>
        <Line red/>

        <Link to={props.to}>
            <Subtitle>
                <Text tiny>Next</Text>
            </Subtitle>
            
            <Text xlarge block>{props.title}</Text>
            
            <Blob>
                <svg x="0px" y="0px" viewBox="0 0 500 500">
                    <path d="M5.5,219C29.2,106.8,130.4-1.3,250.5,0c165.3,1.8,283.9,209.8,239,339c-25.5,73.3-99.1,108.7-131,124 c-25.1,12.1-180.8,82.7-287-8C-24.7,372.9,3.2,229.7,5.5,219z" />
                </svg>
            </Blob>
        </Link>
    </Next>    
)
