import React from 'react'
import styled, {keyframes} from 'styled-components'
import left from '../assets/images/arrow-ltr.svg'
import right from '../assets/images/arrow-rtl.svg'
import { vw, fadeInLeft, fadeInRight } from '../utilities/helpers'

export const ltr = keyframes`
    from {
        transform: translate3d(-1vw, 0, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
`

export const rtl = keyframes`
    from {
        transform: translate3d(1vw, 0, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
`

const Media = styled.div`
  position: absolute;
  right: ${vw(2)};
  width: ${vw(8)};
  height: ${vw(8)};
  z-index: 0;
  top: ${vw(3)};

  figure{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: ${vw(6)};
    height: ${vw(7.5)};
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-duration: 1.25s;
    animation-fill-mode: both;
  }

  img{
    width: 100%; 
    height: 100%;
    object-fit: contain;
    animation-timing-function: linear;
    animation-fill-mode: both;
    animation-duration: ${props => props.duration}s;
  }

  @media (max-width: 1023px){
      right: ${vw(1)};
  }

  @media (max-width: 767px){
      position: relative;
      width: 100vw;
      height: auto;
      padding-bottom: 100%;
      margin: ${vw(6)} auto ${vw(3.5)};
      right: auto;
      top: 0;

      figure{
          width: 70%;
          height: 100%;
      }
  }
`

const Front = styled.figure`
    left: 0;
    z-index: 1;
    background-image: url(${left});
    animation-name: ${fadeInRight};
    animation-delay: 0.1s;

    ${'' /* img{
        animation-name: ${ltr};
    } */}
`

const Back = styled.figure`
    right: 0;
    z-index: 0;
    background-image: url(${right});
    animation-name: ${fadeInLeft};
    animation-delay: 0.2s;

    ${'' /* img{
        animation-name: ${rtl};
    } */}
`

export default(props) => {
    return (
        <Media duration={props.duration}>
            <Front>
                <img src={props.front} alt="Intergenerational dialogue"/>
            </Front>
            <Back>
                <img src={props.back} alt="Intergenerational dialogue"/>
            </Back>
        </Media>    
    )
}